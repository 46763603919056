<template>
  <div id="inspectionEar">
    <el-dialog
      :title="inspectionEarFormTitle"
      width="1200px"
      :visible.sync="inspectionEarDialogVisible"
      :close-on-click-modal="false"
      @close="inspectionEarDialogClose"
    >
      <el-form
        ref="inspectionEarFormRef"
        :model="inspectionEarForm"
        :rules="inspectionEarFormRules"
        label-position="right"
        label-width="100px"
      >
        <el-row>
          <el-col :span="8">
            <el-form-item label="产品名称" prop="productName">
              <el-input v-model="inspectionEarForm.productName" placeholder="请输入产品名称" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="批号" prop="batchNo">
              <el-input v-model="inspectionEarForm.batchNo" placeholder="请输入批号" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="型号/规格" prop="spec">
              <el-input v-model="inspectionEarForm.spec" placeholder="请输入型号/规格" clearable />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="取样量" prop="sampleSize">
              <el-input v-model="inspectionEarForm.sampleSize" placeholder="请输入取样量" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="检验日期" prop="inspectionDate">
              <el-date-picker v-model="inspectionEarForm.inspectionDate" placeholder="请选择日期" value-format="yyyy-MM-dd" />
            </el-form-item>
          </el-col>
        </el-row>
        <span>耳带</span>
        <el-divider content-position="left"></el-divider>
        <el-row>
          <el-col :span="24">
            <el-form-item label="外观：白色、表面干净，无明显刮痕、压痕、划伤、脏污等现象。" label-width="460px">
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="样品1：" prop="earExteriorResult1">
              <el-radio-group v-model="inspectionEarForm.earExteriorResult1">
                <el-radio :label="1">
                  合格
                </el-radio>
                <el-radio :label="0">
                  不合格
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="样品2：" prop="earExteriorResult2">
              <el-radio-group v-model="inspectionEarForm.earExteriorResult2">
                <el-radio :label="1">
                  合格
                </el-radio>
                <el-radio :label="0">
                  不合格
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="样品3：" prop="earExteriorResult3">
              <el-radio-group v-model="inspectionEarForm.earExteriorResult3">
                <el-radio :label="1">
                  合格
                </el-radio>
                <el-radio :label="0">
                  不合格
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
        <el-divider><i class="el-icon-mobile-phone"></i></el-divider>
        <el-row>
          <el-col :span="24">
            <el-form-item label="直径：0.25-0.3cm" label-width="160px">
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="样品1：" prop="earDiameterValue1">
              <el-input v-model="inspectionEarForm.earDiameterValue1" placeholder="请输入直径" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="样品2：" prop="earDiameterValue2">
              <el-input v-model="inspectionEarForm.earDiameterValue2" placeholder="请输入直径" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="样品3：" prop="earDiameterValue3">
              <el-input v-model="inspectionEarForm.earDiameterValue3" placeholder="请输入直径" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="样品1：" prop="earDiameterResult1">
              <el-radio-group v-model="inspectionEarForm.earDiameterResult1">
                <el-radio :label="1">
                  合格
                </el-radio>
                <el-radio :label="0">
                  不合格
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="样品2：" prop="earDiameterResult2">
              <el-radio-group v-model="inspectionEarForm.earDiameterResult2">
                <el-radio :label="1">
                  合格
                </el-radio>
                <el-radio :label="0">
                  不合格
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="样品3：" prop="earDiameterResult3">
              <el-radio-group v-model="inspectionEarForm.earDiameterResult3">
                <el-radio :label="1">
                  合格
                </el-radio>
                <el-radio :label="0">
                  不合格
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
        <el-divider><i class="el-icon-mobile-phone"></i></el-divider>
        <el-row>
          <el-col :span="24">
            <el-form-item label="备注" prop="earRemarks">
              <el-input
                v-model="inspectionEarForm.earRemarks"
                placeholder="请输入备注"
                type="textarea"
                :rows="4"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="10">
            <el-form-item label="检验人" prop="inspector">
              <el-input v-model="inspectionEarForm.inspector" placeholder="请输入检验人" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="检验操作日期" prop="operationDate">
              <el-date-picker v-model="inspectionEarForm.operationDate" placeholder="请选择日期" value-format="yyyy-MM-dd" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="10">
            <el-form-item label="审核人" prop="reviewer">
              <el-input v-model="inspectionEarForm.reviewer" placeholder="请输入审核人" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="审核时间" prop="reviewDate">
              <el-date-picker v-model="inspectionEarForm.reviewDate" placeholder="请选择日期" value-format="yyyy-MM-dd" />
            </el-form-item>
          </el-col>
        </el-row>
        
      </el-form>
      <div slot="footer">
        <el-button @click="inspectionEarDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="inspectionEarFormSubmit">
          确 定
        </el-button>
      </div>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item label="部门">
        <el-input v-model="searchForm.dept" placeholder="请输入部门" clearable />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
        <el-button type="primary" icon="el-icon-plus" @click="handleAdd">
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="inspectionEarPage.list"
      row-key="id"
      :cell-style="{padding: '6px'}"
      :header-cell-style="{background: '#f8f8f9'}"
      :height="getTableHeight()"
    >
      <el-table-column prop="productName" label="产品名称" />
      <el-table-column prop="batchNo" label="批号" />
      <el-table-column prop="spec" label="型号/规格" />
      <el-table-column prop="sampleSize" label="取样量" />
      <el-table-column prop="inspectionDate" label="检验日期" />
      <el-table-column prop="inspector" label="检验人" />
      <el-table-column prop="operationDate" label="检验操作日期" />
      <el-table-column prop="reviewer" label="复核人" />
      <el-table-column prop="reviewDate" label="复核日期" />
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <el-button
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="inspectionEarPage.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import { addInspection, deleteInspection, updateInspection, selectInspectionInfo, selectInspectionList } from '@/api/quality/rawMaterialInspection'

export default {
  data () {
    return {
      inspectionEarDialogVisible: false,
      inspectionEarFormTitle: '',
      inspectionEarForm: {
        id: '',
        productName: '',
        batchNo: '',
        spec: '',
        sampleSize: '',
        inspectionDate: '',
        earExteriorResult1: '',
        earExteriorResult2: '',
        earExteriorResult3: '',
        earDiameterResult1: '',
        earDiameterResult2: '',
        earDiameterResult3: '',
        earDiameterValue1: '',
        earDiameterValue2: '',
        earDiameterValue3: '',
        inspector: '',
        operationDate: '',
        reviewer: '',
        reviewDate: '',
        earRemarks: '',
        type: '2'
      },
      inspectionEarFormRules: {
        productName: [{ required: true, message: '产品名称不能为空', trigger: ['blur', 'change']}]
      },
      inspectionEarPage: {
        list: [],
        total: 0
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        type: '2',
        dept: ''
      },
      resultList: [
        { label: '符合规定', value: '1' },
        { label: '不符合规定', value: '2' }
      ]
    }
  },
  created () {
    selectInspectionList(this.searchForm).then(res => {
      this.inspectionEarPage = res
    })
  },
  methods: {
    inspectionEarDialogClose () {
      this.$refs.inspectionEarFormRef.resetFields()
      this.inspectionEarForm.id = ''
    },
    inspectionEarFormSubmit () {
      if (this.inspectionEarFormTitle === '(耳带)一次性使用医用口罩原材料检验记录详情') {
        this.inspectionEarDialogVisible = false
        return
      }
      this.$refs.inspectionEarFormRef.validate(async valid => {
        if (valid) {
          
          if (this.inspectionEarFormTitle === '新增(耳带)一次性使用医用口罩原材料检验记录') {
            await addInspection(this.inspectionEarForm)
          } else if (this.inspectionEarFormTitle === '修改(耳带)一次性使用医用口罩原材料检验记录') {
            await updateInspection(this.inspectionEarForm)
          }
          this.inspectionEarPage = await selectInspectionList(this.searchForm)
          this.inspectionEarDialogVisible = false
        }
      })
    },
    handleAdd () {
      this.inspectionEarFormTitle = '新增(耳带)一次性使用医用口罩原材料检验记录'
      this.inspectionEarDialogVisible = true
    },
    handleDelete (index, row) {
      this.$confirm('确认删除？', '提示', {
        type: 'warning'
      }).then(async () => {
        await deleteInspection(row.id)
        if (this.inspectionEarPage.list.length === 1 && this.searchForm.pageNum > 1) {
          this.searchForm.pageNum--
        }
        this.inspectionEarPage = await selectInspectionList(this.searchForm)
      })
    },
    handleUpdate (index, row) {
      this.inspectionEarFormTitle = '修改(耳带)一次性使用医用口罩原材料检验记录'
      this.inspectionEarDialogVisible = true
      this.selectInspectionInfoById(row.id)
    },
    handleInfo (index, row) {
      this.inspectionEarFormTitle = '(耳带)一次性使用医用口罩原材料检验记录详情'
      this.inspectionEarDialogVisible = true
      this.selectInspectionInfoById(row.id)
    },
    selectInspectionInfoById (id) {
      selectInspectionInfo(id).then(res => {
        this.inspectionEarForm.id = res.id
        this.inspectionEarForm.productName = res.productName
        this.inspectionEarForm.batchNo = res.batchNo
        this.inspectionEarForm.spec = res.spec
        this.inspectionEarForm.sampleSize = res.sampleSize
        this.inspectionEarForm.inspectionDate = res.inspectionDate
        this.inspectionEarForm.earExteriorResult1 = res.earExteriorResult1
        this.inspectionEarForm.earExteriorResult2 = res.earExteriorResult2
        this.inspectionEarForm.earExteriorResult3 = res.earExteriorResult3
        this.inspectionEarForm.earDiameterResult1 = res.earDiameterResult1
        this.inspectionEarForm.earDiameterResult2 = res.earDiameterResult2
        this.inspectionEarForm.earDiameterResult3 = res.earDiameterResult3
        this.inspectionEarForm.earDiameterValue1 = res.earDiameterValue1
        this.inspectionEarForm.earDiameterValue2 = res.earDiameterValue2
        this.inspectionEarForm.earDiameterValue3 = res.earDiameterValue3
        this.inspectionEarForm.earRemarks = res.earRemarks
        this.inspectionEarForm.inspector = res.inspector
        this.inspectionEarForm.operationDate = res.operationDate
        this.inspectionEarForm.reviewer = res.reviewer
        this.inspectionEarForm.reviewDate = res.reviewDate
      })
    },
    handleSearch () {
      this.searchForm.pageNum = 1
      selectInspectionList(this.searchForm).then(res => {
        this.inspectionEarPage = res
      })
    },
    pageNumChange (pageNum) {
      this.searchForm.pageNum = pageNum
      selectInspectionList(this.searchForm).then(res => {
        this.inspectionEarPage = res
      })
    },
    pageSizeChange (pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.pageNum = 1
      selectInspectionList(this.searchForm).then(res => {
        this.inspectionEarPage = res
      })
    }
  }
}
</script>

<style>
.vxe-select--panel {
  z-index: 3000 !important;
}
.vxe-input--panel {
  z-index: 3000 !important;
}
</style>
